import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "bind-bank"
};
const _hoisted_2 = {
  class: "bind-bank__box"
};
const _hoisted_3 = {
  class: "bind-bank__item"
};
const _hoisted_4 = ["placeholder"];
const _hoisted_5 = {
  class: "bind-bank__item"
};
const _hoisted_6 = ["placeholder"];
const _hoisted_7 = {
  class: "bind-bank__item"
};
const _hoisted_8 = ["placeholder"];
const _hoisted_9 = {
  class: "bind-bank__item"
};
const _hoisted_10 = ["placeholder"];
import { router } from '@/router';
import { UpdateBank } from '@/api';
import { ref } from 'vue';
import { showLoadingToast, showToast } from 'vant';
import { useUserStore } from '@/store/user';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'BindBank',
  setup(__props) {
    const {
      t
    } = useI18n();
    const onClickLeft = () => {
      router.back();
    };
    // const formData = ref({
    //   phone: '',
    //   bank_owner: '',
    //   card: '',
    //   bank_name: '',
    //   branch: '',
    //   route: '',
    // });
    const userStore = useUserStore();
    userStore.updateBankData();
    const formData = ref(userStore.bankData);
    const _UpdateBank = () => {
      if (!formData.value.phone) {
        showToast(t('Mobile phone number cannot be empty'));
        return;
      }
      if (!formData.value.bank_owner) {
        showToast(t('Account Holder Name cannot be empty'));
        return;
      }
      if (!formData.value.card) {
        showToast(t('Account Number cannot be empty'));
        return;
      }
      if (!formData.value.bank_name) {
        showToast(t('Bank Name cannot be empty'));
        return;
      }
      if (!formData.value.branch) {
        showToast(t('Branch cannot be empty'));
        return;
      }
      if (!formData.value.route) {
        showToast(t('Routing Number cannot be empty'));
        return;
      }
      showLoadingToast();
      UpdateBank(formData.value).then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          onClickLeft();
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        fixed: "",
        placeholder: "",
        title: _ctx.$t('Bind bank account'),
        "left-arrow": "",
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Mobile phone number')), 1), _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: _ctx.$t('Please enter your mobile phone number'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.phone = $event)
      }, null, 8, _hoisted_4), [[_vModelText, formData.value.phone]])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Account Holder Name')), 1), _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: _ctx.$t('Please Enter Your Name'),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.value.bank_owner = $event)
      }, null, 8, _hoisted_6), [[_vModelText, formData.value.bank_owner]])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Account Number')), 1), _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: _ctx.$t('Bank Card Number Cannot Be empty'),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.card = $event)
      }, null, 8, _hoisted_8), [[_vModelText, formData.value.card]])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Bank Name')), 1), _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: _ctx.$t('Please Enter Your Bank'),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.value.bank_name = $event)
      }, null, 8, _hoisted_10), [[_vModelText, formData.value.bank_name]])]), _createElementVNode("div", {
        class: "bind-bank__btn",
        onClick: _cache[4] || (_cache[4] = $event => _UpdateBank())
      }, _toDisplayString(_ctx.$t('Confirm')), 1)])]);
    };
  }
};